import { Button, Container, Header, Image, Text } from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { noop } from '../../../../lib/utils';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function Hero({ blocks }) {
  const [{ description, image }] = blocks;
  const imageToSrc = useImageSrcBuilder();
  const backgroundImage = image?.url && `url(${imageToSrc(image)})`;

  return (
    <div className={cx('hero')} style={{ backgroundImage }}>
      {description && (
        <div className={cx('title')}>
          <Container>
            <Header as="h1">
              <Markup source={description} />
            </Header>
          </Container>
        </div>
      )}
    </div>
  );
}

Hero.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

Hero.defaultProps = {
  blocks: [],
};

export function HeroSideInfo({ backgroundColor, blocks, infoRight, onClick, textColor }) {
  const [{
    ctaDisclaimer,
    ctaLabel,
    ctaUrl,
    description,
    descriptionAlignment,
    descriptionSize,
    image,
    title,
    titleAlignment,
    titleImage,
    titleSize,
  }] = blocks;
  const imageToSrc = useImageSrcBuilder();
  const { mobile: isMobileSize, tablet: isTabletSize } = useWindowSize();

  const sectionStyles = useMemo(() => {
    const newStyles = {};

    // TODO: Update Netlify with a mobileImage field inside blocks object array
    if ((isMobileSize || isTabletSize) && blocks[1].image) {
      newStyles.backgroundImage = `url(${imageToSrc(blocks[1].image)})`;
    } else if (image) {
      newStyles.backgroundImage = `url(${imageToSrc(image)})`;
    }

    if (textColor) {
      newStyles.color = textColor;
    }

    if (backgroundColor) {
      newStyles.backgroundColor = backgroundColor;
    }

    return newStyles;
  }, [backgroundColor, blocks, image, imageToSrc, isMobileSize, isTabletSize, textColor]);

  const infoSectionStyles = useMemo(() => {
    // TODO: Update Netlify with a textBackgroundColor field to account for mobile and tablet sizes
    if ((isMobileSize || isTabletSize) && textColor) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.57)',
      };
    }

    return undefined;
  }, [isMobileSize, isTabletSize, textColor]);

  const descriptionStyles = {};
  if (descriptionAlignment) {
    descriptionStyles.textAlign = descriptionAlignment;
  }
  if (descriptionSize) {
    descriptionStyles.fontSize = descriptionSize;
  }

  const titleStyles = {};
  if (titleAlignment) {
    titleStyles.textAlign = titleAlignment;
  }

  return (
    <section className={cx('hero', 'section')} style={sectionStyles}>
      <Container>
        <div className={cx('info', { right: infoRight })} style={infoSectionStyles}>
          <Header as="h2" className={cx('header', !titleImage ? (titleSize || '') : '')} style={titleStyles}>
            {titleImage?.url ? (
              <Image alt={title} className={cx('titleImage')} src={imageToSrc(titleImage)} />
            ) : (
              <Markup source={title} />
            )}
          </Header>
          <div className={cx('description')} style={descriptionStyles}>
            <Markup source={description} />
          </div>
          {ctaLabel && ctaUrl && (
            <Button
              as="a"
              className={cx('cta')}
              href={ctaUrl}
              onClick={onClick}
              primary
              size="lg"
              wide
            >
              {ctaLabel}
            </Button>
          )}
          {ctaDisclaimer && (
            <Text className={cx('ctaDisclaimer')} size="sm">{ctaDisclaimer}</Text>
          )}
        </div>
      </Container>
    </section>
  );
}

HeroSideInfo.propTypes = {
  backgroundColor: PropTypes.string,
  blocks: PropTypes.arrayOf(Block),
  infoRight: PropTypes.bool,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
};

HeroSideInfo.defaultProps = {
  backgroundColor: undefined,
  blocks: [],
  infoRight: false,
  onClick: noop,
  textColor: undefined,
};
